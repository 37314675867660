<template>
  <div id="dashboard">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div>
        <!--<b-alert show variant="danger">
          <h4 class="alert-heading">¡Sistema en construcción!</h4>
          <hr />
          <b class="mb-0">
            El sistema de matrículas estara activo a partir del "02 de marzo
            3pm". Gracias por registrarse.
          </b>
        </b-alert>-->

        <b-jumbotron
          class="pt-0 pb-3 mb-3"
          style="
            background-image: url(https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/ImagenDashboard.jpg?alt=media);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          "
          text-variant="white"
        >
          <template #header class="borderText">UCPS </template>
          <template #lead>
            <p class="borderText">
              Unidad de Capacitación de Producción y Servicios
            </p>
            <p class="borderText">Sistema de matrículas</p>
          </template>
          <!--<b-button size="lg" variant="warning" to="/program"
            ><b-icon icon="forward" animation="cylon" /> Matricularse</b-button
          >-->
        </b-jumbotron>
        <!--carousel-->
        <!--
        <div>
          <b-carousel
            class="mb-3"
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="rgb(243, 243, 243)"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              img-src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/Carusel01.jpeg?alt=media"
            >
              <h4 style="text-shadow: 1px 1px 2px #333" class="borderText">
                Sistema de matrículas
              </h4>
              <b-button
                class="my-2 border-light"
                size="lg"
                variant="warning"
                to="/program"
                ><b-icon icon="forward" animation="cylon" />
                Matricularse</b-button
              ></b-carousel-slide
            >
            <b-carousel-slide
              img-src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/Carusel02.jpeg?alt=media"
              ><h4 style="text-shadow: 1px 1px 2px #333" class="borderText">
                Sistema de matrículas
              </h4>
              <b-button
                class="my-2 border-light"
                size="lg"
                variant="warning"
                to="/program"
                ><b-icon icon="forward" animation="cylon" />
                Matricularse</b-button
              ></b-carousel-slide
            >
          </b-carousel>
        </div>-->
        <!---->

        <!--<b-alert show variant="success">
          <h4 class="alert-heading">¡Importante!</h4>
          <p>
            La selección de turnos ya está disponible en el panel de espacio de
            trabajo.
          </p>
          <hr />
          <b class="mb-0">
            <b-icon-shift-fill rotate="180" /> El panel de espacio de trabajo se
            encuentra aquí. <b-icon-shift-fill rotate="180" />
          </b>
        </b-alert>-->
        <section id="workspace">
          <div class="card mb-3">
            <div class="card-header">
              <h1><b-icon icon="diagram3" /> Espacio de Trabajo</h1>
            </div>
            <div class="card-body">
              <!-- Selección de turnos -->
              <!--<div v-if="tamArre(desserts.enrollmentPrograms)" class="card">
              <div class="card-header">
                <h3><b-icon icon="pen-fill" /> Selección de turnos</h3>
              </div>
              <div style="background-color: #ffc107" class="card-body">
                <vue-glide :perView="count">
                  <vue-glide-slide
                    v-for="(aux, i) in desserts.enrollmentPrograms"
                    :key="i"
                  >
                    <b-card :header="aux.periodName" class="text-center">
                      <center>
                        <b-button @click="getInfoTurn(aux)" variant="secondary"
                          ><b-icon icon="forward" animation="cylon" />
                          Seleccionar turno</b-button
                        >
                      </center>
                    </b-card>
                  </vue-glide-slide>
                  <template
                    v-if="slotControl(desserts.enrollmentPrograms)"
                    slot="control"
                  >
                    <button data-glide-dir="<">&lt;</button>
                    <button data-glide-dir=">">&gt;</button>
                  </template>
                </vue-glide>
              </div>
            </div>-->
              <!--Mis programas-->
              <div
                class="card mt-3"
                v-show="
                  desserts.allEnrollmentProgram.length != 0 ||
                  desserts.allEnrollmentProgramChecking.length != 0
                "
              >
                <div
                  class="card-header"
                  style="background-color: rgb(119, 35, 45)"
                >
                  <h3><b-icon icon="journal-bookmark" /> Mis programas</h3>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <!--Seleccion de turno-->
                    <div
                      class="col"
                      v-for="(aux, i) in desserts.allEnrollmentProgram"
                      :key="i"
                    >
                      <b-jumbotron
                        class="pt-0 pt-3"
                        style="
                          background-image: url(https://www.ecestaticos.com/imagestatic/clipping/39e/309/39e309dff6a35294c9eae324531ec5c7/los-libros-que-se-han-censurado-o-perseguido-en-estados-unidos-en-2018.jpg?mtime=1622642908);
                          background-position: center center;
                          background-repeat: no-repeat;
                          background-size: cover;
                          min-width: 300px;
                          height: 90%;
                        "
                        text-variant="white"
                      >
                        <template #lead>
                          <h4>
                            <b-badge variant="light">{{
                              aux.program.label
                            }}</b-badge>
                          </h4>
                        </template>
                        <b-button
                          class="border-light"
                          @click="getInfoTurn(aux)"
                          variant="warning"
                          ><b-icon icon="forward" animation="cylon" />
                          Seleccionar turno</b-button
                        >
                      </b-jumbotron>
                    </div>
                    <!--Vista del estudiante programa-->
                    <div
                      class="col"
                      v-for="(aux, i) in desserts.allEnrollmentProgramChecking"
                      :key="'A' + i"
                    >
                      <b-jumbotron
                        class="pt-0 pt-3"
                        style="
                          background-image: url(https://www.ecestaticos.com/imagestatic/clipping/39e/309/39e309dff6a35294c9eae324531ec5c7/los-libros-que-se-han-censurado-o-perseguido-en-estados-unidos-en-2018.jpg?mtime=1622642908);
                          background-position: center center;
                          background-repeat: no-repeat;
                          background-size: cover;
                          min-width: 300px;
                          height: 90%;
                        "
                        text-variant="white"
                      >
                        <template #lead>
                          <h4>
                            <b-badge variant="light">{{
                              aux.program.label
                            }}</b-badge>
                          </h4>
                          <h4>
                            <b-badge variant="light">{{
                              aux.groupTeaching.label
                            }}</b-badge>
                          </h4>
                        </template>
                        <b-button-group vertical>
                          <ModalCourses :idEnrollment="aux.enrollId + ''" />
                          <ModalPayment :dataProgram="aux" />
                          <div v-if="aux.period.id == 1646230421226723">
                            <!--temporal -->
                            <div v-show="aux.program.id == '1646240968183181'">
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902166822'
                                "
                                target="_blank"
                                href="https://chat.whatsapp.com/Gaj3bIqakuFBPyYtfGIJBp"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Mañana A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902166989'
                                "
                                target="_blank"
                                href="https://chat.whatsapp.com/JfYG7xDHuBq8UDGDqO3w0h"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Tarde A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902167341'
                                "
                                target="_blank"
                                href="https://chat.whatsapp.com/BneM3nK01e8E3IkfgpO5SL"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902167677'
                                "
                                target="_blank"
                                href="https://chat.whatsapp.com/Fe7wq4fKzgSAiFQJ7HkCan"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Fin de semana A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902168129'
                                "
                                target="_blank"
                                href="https://chat.whatsapp.com/IcTdpwFYxZGGgUdxhw3lgB"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche B</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646427491117583'
                                "
                                target="_blank"
                                href="https://chat.whatsapp.com/JqSq8o3NgfM4LFgYAirFhp"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche C</b></b-button
                              >
                            </div>
                            <!---->
                            <!--Horario-->
                            <!--Seguridad Industrial-->
                            <div v-show="aux.program.id == '1646240968183181'">
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902166822'
                                "
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'SEGURIDAD_MINERA_MÑN' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Mañana A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902166989'
                                "
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'SEGURIDAD_MINERA_TARDE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Tarde A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902167341'
                                "
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'SEGURIDAD_MINERA_NOCHE_A' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902167677'
                                "
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'SEGURIDAD_MINERA_FIN_SEMANA' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Fin de semana A</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646425902168129'
                                "
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'SEGURIDAD_MINERA_NOCHE_B' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche B</b></b-button
                              >
                              <b-button
                                v-show="
                                  aux.groupTeaching.id == '1646427491117583'
                                "
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'SEGURIDAD_MINERA_NOCHE_C' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche C</b></b-button
                              >
                            </div>
                            <!---->
                            <!--ambiental-->
                            <div v-show="aux.program.id == '1646241400247751'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/JngOMw8v9PKI7RJ0MbONqy"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'AMBIENTAL_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--computacion-->
                            <div v-show="aux.program.id == '1646254777371844'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/CVtRjvTg0Z143hBvCoPgAm"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'COMPUTACION_INFORMATICA_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--gestion de sistemas-->
                            <div v-show="aux.program.id == '1646263659071415'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/KmjMaIHqdve3jDhX57B4eW"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              ><b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'GESTION_SISTEMAS_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>

                            <!--logistica y almacenes-->
                            <div v-show="aux.program.id == '1646263687314268'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/IT5BCbMG5OqLCSru55xZ9X"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'GESTION_LOGISTICA_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--aduanas-->
                            <div v-show="aux.program.id == '1646263710142486'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/LlenvyYbzWu3d9s4k0Bw5P"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'ADUANAS_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--marketing-->
                            <div v-show="aux.program.id == '1646263730748779'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/JB1iI0kNS29HkqV6yskQKx"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'MARKETING_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--asistente administrativo-->
                            <div v-show="aux.program.id == '1646263747041395'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/K8qaXoy83jo0raRnfamlGs"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'ASISTENTE_ADM_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--contabilidad-->
                            <div v-show="aux.program.id == '1646263768529391'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/F9fbbnT0aKVFf4VcuJDXNZ"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'CONTABILIDAD_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!--administracion bancaria-->
                            <div v-show="aux.program.id == '1646263785354375'">
                              <b-button
                                target="_blank"
                                href="https://chat.whatsapp.com/FpXSdCB4kdY7EAFHhYuHM8"
                                variant="success"
                                class="border-light"
                                ><div class="fab fa-whatsapp" />
                                WhatsApp <b>Noche A</b></b-button
                              >
                              <b-button
                                target="_blank"
                                :href="
                                  $urlFirebaseSchedule +
                                  'ADM_BANCARIA_NOCHE' +
                                  '.jpeg?alt=media'
                                "
                                variant="warning"
                                class="border-light"
                              >
                                Horario <b>Noche A</b></b-button
                              >
                            </div>
                            <!---->
                          </div>
                        </b-button-group>
                      </b-jumbotron>
                    </div>
                  </div>
                </div>
              </div>
              <!--Anuncio manual-->
              <!--<div v-show="desserts.allEnrollmentProgramChecking.length != 0">
                <div class="card-body pb-0">
                  <a
                    :href="
                      $urlFirebaseAssortedDocuments +
                      'MANUAL_UCPS_2022_1.pdf' +
                      '?alt=media'
                    "
                    target="_blank"
                  >
                    <b-button
                      style="background-color: #1d425e; color: white; border: none"
                      class="w-100"
                      ><b-icon icon="forward" animation="cylon" /> Manual para ingresar a tus clases virtuales</b-button
                    ></a
                  >
                </div>
              </div>-->
              <!--Mis cursos-->
              <div
                class="card mt-3"
                v-show="desserts.allEnrollmentCourse.length != 0"
              >
                <div
                  class="card-header"
                  style="background-color: rgb(119, 35, 45)"
                >
                  <h3><b-icon icon="journal-text" /> Mis Cursos</h3>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <!--Vista del docente en curso-->
                    <div
                      class="col"
                      v-for="(aux, i) in desserts.allEnrollmentCourse"
                      :key="'B' + i"
                    >
                      <b-jumbotron
                        class="pt-0 pt-3"
                        style="
                          background-image: url(https://www.ecestaticos.com/imagestatic/clipping/39e/309/39e309dff6a35294c9eae324531ec5c7/los-libros-que-se-han-censurado-o-perseguido-en-estados-unidos-en-2018.jpg?mtime=1622642908);
                          background-position: center center;
                          background-repeat: no-repeat;
                          background-size: cover;
                          min-width: 300px;
                          height: 90%;
                        "
                        text-variant="white"
                      >
                        <template #lead>
                          <h4>
                            <b-badge variant="light">{{
                              aux.courseName
                            }}</b-badge>
                          </h4>
                          <h4>
                            <b-badge variant="light">{{
                              aux.cycle
                            }}</b-badge>
                          </h4>
                          <h4>
                            <b-badge variant="light">{{
                              aux.groupTeachingName
                            }}</b-badge>
                          </h4>
                        </template>
                        <b-button-group vertical>
                          <b-button
                            :to="'/course/' + aux.courseId"
                            class="border-light"
                            >Ver curso</b-button
                          >
                        </b-button-group>
                      </b-jumbotron>
                    </div>
                  </div>
                </div>
              </div>
              <!--Mis cursos-->
              <!--<div v-if="tamArre(desserts.enrollmentCourses)" class="card mt-3">
              <div
                class="card-header"
                style="background-color: rgb(119, 35, 45)"
              >
                <h3><b-icon icon="journal-bookmark" /> Mis cursos</h3>
              </div>
              <div class="card-body">
                <vue-glide :perView="count">
                  <vue-glide-slide
                    v-for="(aux, i) in desserts.enrollmentCourses"
                    :key="i"
                  >
                    <b-jumbotron
                      class="pt-0 pb-3 mb-0"
                      type="button"
                      @click="toCourseDetail(aux.courseId)"
                      :header="'C' + (i + 1)"
                      :lead="aux.courseName"
                      style="
                        background-image: url(https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/ImagenCursos.jpg?alt=media);
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 220px;
                      "
                      text-variant="white"
                    >
                    </b-jumbotron>
                  </vue-glide-slide>
                  <template
                    v-if="slotControl(desserts.enrollmentCourses)"
                    slot="control"
                  >
                    <button data-glide-dir="<">&lt;</button>
                    <button data-glide-dir=">">&gt;</button>
                  </template>
                </vue-glide>
              </div>
            </div>-->
            </div>
          </div>
        </section>
        <b-alert show variant="primary">
          <h5>Correos de contacto:</h5>
          <hr />
          <p class="mb-0">
            <b
              ><em><u>soporte_virtual@ucps.unsa.pe</u></em></b
            >
            /
            <b
              ><em><u>cobranza_academica@ucps.unsa.pe</u></em></b
            >
            /
            <b
              ><em><u>secretaria_academica@ucps.unsa.pe</u></em></b
            >
          </p>
        </b-alert>
      </div>
    </div>
    <!--Seleccion de turno-->
    <div>
      <b-modal
        scrollable
        centered
        v-model="showTurn"
        title="Selección de Turno"
      >
        <b-container fluid>
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Seleccione un turno:</label>
              <b-form-select
                :state="dataInfo != null"
                v-model="dataInfo"
                :options="dataInfoTurn"
                required
              ></b-form-select>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              :disabled="dataInfo == null"
              @click="saveAddItem()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Agregar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Ingresar telefono-->
    <div>
      <b-modal
        scrollable
        centered
        v-model="showPhone"
        title="Actualización de datos"
      >
        <b-container fluid>
          <AlertError ref="error" />
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="exampleInputEmail1"><b>Número celular:</b></label>
                <b-form-group description="Número celular de 9 digitos.">
                  <b-form-input
                    type="number"
                    class="form-control"
                    placeholder="Ingrese celular"
                    v-model="dataOperators.number"
                    :state="verifyPhone(dataOperators)"
                    required
                  />
                </b-form-group>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label for="exampleInputEmail1"><b>Operadora:</b></label>
                <b-form-select
                  v-model="dataOperators.operator"
                  :state="dataOperators.operator != null"
                  :options="operators"
                />
              </div>
            </div>
          </div>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddPhone"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Enviar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import AlertError from "../../components/AlertError.vue";
import ModalCourses from "./Modal-courses.vue";
import ModalPayment from "./Modal-payment.vue";
export default {
  components: {
    AlertError,
    ModalCourses,
    ModalPayment,
  },
  data: () => ({
    //Carousel
    slide: 0,
    sliding: null,
    //
    selected: "",
    count: 3,
    desserts: {
      allEnrollmentProgramChecking: [],
      allEnrollmentProgram: [],
      allEnrollmentCourse: [],
    },
    dataInfoTurn: [],
    dataInfo: null,
    showTurn: false,
    showPhone: false,
    dataAddInfo: {},
    operators: [],
    dataOperators: {}, //data para cambiar telefono
  }),

  created() {
    this.initialize();
    if (window.innerWidth < 1000) {
      this.count = 1;
    } else {
      this.count = 3;
    }
    if (`${localStorage.checkPhone}` === "false") {
      this.getOperators();
    }
  },

  methods: {
    //Carousel
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    //
    verifyPhone(data) {
      if (data.number != null && data.number.length == 9) return true;
      else return false;
    },
    toCourseDetail(data) {
      this.$router.push("/course/" + data);
    },
    exit() {
      this.showTurn = false;
    },
    tamArre(dato) {
      if (dato == undefined) return false;
      else {
        if (dato.length != 0) return true;
        else return false;
      }
    },
    slotControl(dato) {
      if (dato == undefined) return false;
      else {
        if (dato.length > 1) return true;
        else return false;
      }
    },
    async saveAddPhone() {
      if (
        this.dataOperators.operator == null ||
        !this.verifyPhone(this.dataOperators)
      ) {
        this.$refs.error.showAlert("Ingresar datos correctamente");
        return;
      }
      await this.$axios
        .get(
          "/person/phone/register?number=" +
            this.dataOperators.number +
            "&operator=" +
            this.dataOperators.operator,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.showPhone = false;
          this.$message.success(res.data.content);
          localStorage.setItem("checkPhone", true);
        })
        .catch((error) => {
          this.showPhone = false;
          this.$refs.error.showAlert(error.response.data.content);
        });
    },
    async getOperators() {
      await this.$axios
        .get("/person/phone/register", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.operators = res.data;
          this.showPhone = true;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/dashboard", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.selected = "";
          this.desserts = res.data;
          if (
            this.desserts.allEnrollmentProgramChecking.length != 0 ||
            this.desserts.allEnrollmentProgram.length != 0 ||
            this.desserts.allEnrollmentCourse.length != 0
          ) {
            window.location.href = "#workspace";
          }
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async getInfoTurn(dato) {
      this.dataAddInfo = {};
      this.dataAddInfo.programId = dato.program.id;
      this.dataAddInfo.periodId = dato.period.id;
      this.dataAddInfo.enrollmentProgramId = dato.enrollId;
      this.dataInfo = null;
      this.showTurn = true;
      await this.$axios
        .get(
          "/dashboard/select-group?programId=" +
            dato.program.id +
            "&periodId=" +
            dato.period.id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.dataInfoTurn = res.data;
        })
        .catch((error) => {
          this.showTurn = false;
          this.$errorQuery(error);
        });
    },
    async saveAddItem() {
      this.dataAddInfo.groupId = this.dataInfo;
      //console.log(JSON.stringify(this.dataAddInfo, undefined, "\t"));
      await this.$axios
        .post("/dashboard/select-group", this.dataAddInfo, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.showTurn = false;
          this.$message.success(res.data.content);
          this.initialize();
        })
        .catch((error) => {
          this.showTurn = false;
          this.$message.error(error.response.data.content);
        });
    },
  },
};
</script>
<style scoped>
.badge {
  text-align: left !important;
  white-space: normal !important;
}
</style>
<style scoped>
.display-3 {
  font-weight: 600;
  -webkit-text-stroke: 1px rgb(61, 61, 61);
}
.borderText {
  font-weight: 900;
  -webkit-text-stroke: 0.8px rgb(61, 61, 61);
}
</style>

<style scoped>
.card-body {
  padding: 3% 3%;
}
.card-header {
  background-color: #1d425e;
  color: white;
}
.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card-title {
  height: 70px;
}
</style>

<style scoped>
.nav-tabs .nav-link.active {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  color: black;
  font-weight: bolder;
}
.nav-tabs .nav-link:hover {
  color: white;
}
button[data-glide-dir="<"] {
  position: absolute;
  border: 0;
  outline: 0;
  padding: 5px;
  top: 6%;
  border-radius: 3px;
  border: 1px solid white;
  background-color: #5e151d;
  color: white;
  cursor: pointer;
}
button[data-glide-dir="<"] {
  left: 5px;
}
button[data-glide-dir="<"]:hover {
  background-color: #831a26;
}
button[data-glide-dir=">"] {
  position: absolute;
  border: 0;
  outline: 0;
  padding: 5px;
  top: 6%;
  border-radius: 3px;
  border: 1px solid white;
  background-color: #5e151d;
  color: white;
  cursor: pointer;
}
button[data-glide-dir=">"] {
  right: 5px;
}
button[data-glide-dir=">"]:hover {
  background-color: #831a26;
}
.line {
  text-decoration-line: line-through;
}
.glide__slide {
  width: none;
}
</style>