<template>
  <div id="ModalCourses">
    <b-button @click="add()" class="border-light">Ver cursos</b-button>
    <div>
      <b-modal scrollable centered busy v-model="show" title="Cursos">
        <b-container fluid>
          <!--Contenido modal-->
          <div class="row">
            <div class="col" v-for="(aux, i) in desserts" :key="i">
              <b-jumbotron
                class="pt-0 pb-3"
                type="button"
                @click="toCourseDetail(aux.courseId)"
                style="background-color: #1d425e"
                text-variant="white"
              >
                <template #header
                  ><b-badge variant="light">{{
                    aux.acronym
                  }}</b-badge></template
                >
                <template #lead>
                  <b>{{ aux.courseName }}</b>
                </template>
              </b-jumbotron>
            </div>
          </div>
          <!--fin-->
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="exit()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control"
            >
              Aceptar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCourses",
  props: {
    idEnrollment: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      desserts: [],
    };
  },
  methods: {
    toCourseDetail(data) {
      this.$router.push("/course/" + data);
    },
    exit() {
      this.show = false;
    },
    add() {
      this.initialize();
      this.show = true;
    },
    async initialize() {
      await this.$axios
        .get("/dashboard/enrollment/" + this.idEnrollment + "/course", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>